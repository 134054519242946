// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("E:\\WWW\\viyi\\official-website\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-js": () => import("E:\\WWW\\viyi\\official-website\\src\\pages\\case.js" /* webpackChunkName: "component---src-pages-case-js" */),
  "component---src-pages-contact-us-js": () => import("E:\\WWW\\viyi\\official-website\\src\\pages\\contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("E:\\WWW\\viyi\\official-website\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

